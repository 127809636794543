import http from "./http";
export default (billetId) => ({
  async index(filters) {
    return http
      .index("billets", filters)
      .then((json) => ({ billets: json }))
      .catch(async (e) => ({ billets: null, error: await e }));
  },
  async get(filters) {
    return http
      .get("billets", billetId, filters)
      .then((json) => ({ billet: json }))
      .catch(async (e) => ({ billet: null, error: await e }));
  },
  async create(data) {
    return http
      .post("billets", data)
      .then((json) => ({ billet: json }))
      .catch(async (e) => ({ billet: null, error: await e }));
  },
  async update(data) {
    return http
      .put("billets", billetId, data)
      .then((json) => ({ billet: json }))
      .catch(async (e) => ({ billet: null, error: await e }));
  },
  async delete() {
    return http
      .delete("billets", billetId)
      .then((json) => ({ billet: json }))
      .catch(async (e) => ({ billet: null, error: await e }));
  },
});
