<template>
  <div>
    <el-card shadow="always" v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>{{ $route.name }}</h4>
        <div>
          <el-button
            type="primary"
            icon="el-icon-s-operation"
            size="medium"
            @click="openFilterBilletsModal()"
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="medium"
            @click="openBilletModal(null)"
          ></el-button>
        </div>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Billets"
      style="width: 100%; z-index: 0"
    >
      <el-table-column
        prop="account.firm.name"
        label="empresa"
      ></el-table-column>
      <el-table-column prop="partner.name" label="cliente"></el-table-column>
      <el-table-column
        prop="payment.order.code"
        label="pedido"
      ></el-table-column>
      <el-table-column
        prop="amount"
        :formatter="(r) => currencyFormatter.format(Number(r.amount) || 0)"
        label="valor"
      ></el-table-column>
      <el-table-column
        prop="expires_at"
        :formatter="(r) => dateFormatter.format(new Date(r.expires_at))"
        label="vencimento"
      ></el-table-column>
      <el-table-column
        :formatter="
          (r) =>
            r.amount_paid &&
            currencyFormatter.format(Number(r.amount_paid) || 0)
        "
        label="valor pago"
      ></el-table-column>
      <el-table-column
        :formatter="
          (r) => r.paid_at && dateFormatter.format(new Date(r.paid_at))
        "
        label="data pagamento"
      ></el-table-column>
      <el-table-column label="estado">
        <template #default="c">
          <el-tag :type="statusToType[c.row.status_computed]">{{
            statusToLabel[c.row.status_computed]
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="ações">
        <template #default="{ row: billet }">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                title="Visualizar boleto"
                circle
                @click="openBilletModal(billet)"
              >
                <i class="el-icon-info"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                title="Download do PDF"
                :disabled="!billet?.billet_pdf_url"
                circle
                @click="openInNewTab(billet?.billet_pdf_url)"
              >
                <i class="el-icon-download"></i
              ></el-button>
              <el-popconfirm
                @confirm="removeBillet(billet)"
                title="
              Tem certeza?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-close"></i
                  ></el-button> </template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="billets?.lastPage"
    >
    </el-pagination>
    <billet-modal
      :showModal="showBilletModal"
      :billet="billet"
      @should-update="() => fetchBillets()"
      @close-modal="() => (showBilletModal = false)"
    ></billet-modal>

    <filter-billets-modal
      :showModal="showFilterBilletsModal"
      :billet="billet"
      @update-filters="(v) => updateFilters(v)"
      @close-modal="() => (showFilterBilletsModal = false)"
    ></filter-billets-modal>
  </el-card>
  <el-card v-if="canAny('ViewExecutiveReports')">
    <template #header>
      <el-row type="flex" justify="start">
        <h4>Relatório de boletos</h4>
      </el-row>
    </template>
    <el-descriptions columns="4" size="large">
      <el-descriptions-item>
        <template #label>Boletos em aberto não vencidos</template>
        {{ currencyFormatter.format(Number(report['notExpired'][0]['amountNotExpired'] || 0)) }}
        {{ ' / ' }}
        {{ report["notExpired"][0]['countNotExpired'] + ' Boletos' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Boletos em aberto vencidos</template>
        {{ currencyFormatter.format(Number(report['expired'][0]['amountExpired'] || 0)) }}
        {{ ' / ' }}
        {{ report["expired"][0]['countExpired'] + ' Boletos' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>Boletos pagos</template>
        {{ currencyFormatter.format(Number(report['paid'][0]['amountPaid'] || 0)) }}
        {{ ' / ' }}
        {{ report["paid"][0]['countPaid'] + ' Boletos' }}
      </el-descriptions-item>
    </el-descriptions>
  </el-card>
  </div>
</template>
<script>
import BilletService from "../services/billets";
import FilterBilletsModal from "./modals/FilterBilletsModal.vue";
import AccountService from "../services/accounts";
import BilletModal from "./modals/BilletModal.vue";
import { notifySuccess, notifyError } from "../utils/notifiers";
import { dateFormatter, currencyFormatter } from "../utils/formatters";
import { hasOnlyDigits } from "../utils/functions";
export default {
  name: "Billets",
  data() {
    return {
      showBilletModal: false,
      showFilterBilletsModal: false,
      billet: null,
      billets: null,
      isLoading: true,
      filterOptions: {},
      report: {},
      dateFormatter,
      currencyFormatter,
      currentPage: 1,
      statusToType: {
        created: "info",
        emitted: "info",
        registered: "info",
        error: "danger",
        paid: "success",
        deleted: "danger",
        expired: "warning",
      },
      statusToLabel: {
        created: "CADASTRADO",
        emitted: "EMITIDO",
        registered: "REGISTRADO",
        error: "PROBLEMA",
        paid: "PAGO",
        deleted: "BAIXADO",
        expired: "VENCIDO",
      },
    };
  },
  components: { BilletModal, FilterBilletsModal },
  computed: {
    can() {
      return this.$store.state.auth.can;
    },
    Billets() {
      return this.billets?.data || [];
    },
    SearchName() {
      return this.$store.state.searchNameQuery;
    },
  },
  mounted() {
    this.fetchBillets();
    this.fetchBilletsReport();
  },
  watch: {
    currentPage() {
      this.fetchBillets();
    },
    SearchName() {
      this.fetchBillets();
    },
  },
  methods: {
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
    async fetchBilletsReport(){
      const {billet} = await BilletService('reports').get();

      if(billet){
        this.report = billet;
      }
    },
    async removeBillet(_) {
      const { billet } = await BilletService(_.uid).delete();

      if (billet) notifySuccess(billet.message);
      else notifyError("Ocorreu um erro ao solicitar a baixa");

      this.fetchBillets();
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    openFilterBilletsModal() {
      this.showFilterBilletsModal = true;
    },
    openBilletModal(billet) {
      this.billet = billet;
      this.showBilletModal = true;
    },
    async deleteFirmAccount(acc) {
      const { account, error } = await AccountService(acc.uid).delete();

      if (account) notifySuccess(account.message);
      else notifyError(error.message);
    },
    updateFilters(filters) {
      this.filterOptions = filters;
      this.fetchBillets();
    },
    async fetchBillets() {
      this.isLoading = true;
      const { billets } = await BilletService().index({
        pagination: this.currentPage,
        searchName: hasOnlyDigits(this.SearchName) ? "" : this.SearchName,
        searchNumber: hasOnlyDigits(this.SearchName) ? this.SearchName : "",
        deleted_at: new Date(),
        ...this.filterOptions,
      });

      if (billets) this.billets = billets;

      this.isLoading = false;
    },
  },
};
</script>
