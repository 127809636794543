<template>
  <el-dialog title="Filtrar boletos" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4>Cliente:</h4>
      </el-row>
      <el-select
        v-model="filterOptions.provider_id"
        clearable
        remote
        :remote-method="fetchPartners"
        filterable
        size="medium"
      >
        <el-option
          v-for="item in Partners"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="start">
        <h4>Estado do Boleto:</h4>
      </el-row>
      <el-select
        v-model="filterOptions.status"
        clearable
        multiple
        size="medium"
      >
        <el-option
          v-for="item in definedBilletStatus"
          :key="item"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-row type="flex" justify="start">
        <h4>Período de cadastro:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.created_at_start"
            type="date"
            value-format="YYYY-MM-DD HH:mm:ss"
            size="medium"
            placeholder="Início:"
            :disabled-date="
              (date) => date > new Date(filterOptions?.created_at_end)
            "
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.created_at_end"
            type="date"
            size="medium"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="Fim:"
            :disabled-date="
              (date) => date < new Date(filterOptions?.created_at_start)
            "
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de vencimento:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.expires_at_start"
            type="date"
            value-format="YYYY-MM-DD HH:mm:ss"
            size="medium"
            :disabled-date="
              (date) => date > new Date(filterOptions?.expires_at_end)
            "
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.expires_at_end"
            type="date"
            size="medium"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="Fim:"
            :disabled-date="
              (date) => date < new Date(filterOptions?.expires_at_start)
            "
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row type="flex" justify="start">
        <h4>Período de pagamento:</h4>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.paid_at_start"
            type="date"
            value-format="YYYY-MM-DD HH:mm:ss"
            size="medium"
            :disabled-date="
              (date) => date > new Date(filterOptions?.paid_at_end)
            "
            placeholder="Início:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="filterOptions.paid_at_end"
            type="date"
            size="medium"
            :disabled-date="
              (date) => date < new Date(filterOptions?.paid_at_start)
            "
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="Fim:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="clearFilter"
          >Limpar filtros</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="updateFilters"
          >Filtrar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import PartnerService from "../../services/partners";
import { BILLET_STATUS } from "../../utils/constants";
export default {
  props: ["showModal", "options"],
  emits: ["close-modal", "update-filters"],

  data() {
    return {
      filterOptions: {},
      isLoadingSave: false,
      partners: null,
      definedBilletStatus: BILLET_STATUS,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  watch: {
    options(v) {
      if (v) {
        this.filterOptions = v;
      }
    },
  },
  methods: {
    async fetchPartners(name) {
      const { partners } = await PartnerService().index({
        is_client: true,
        searchName: name || "",
      });

      if (partners) this.partners = partners;
    },
    updateFilters() {
      this.$emit("update-filters", this.filterOptions);
      this.shouldShow = false;
    },
    clearFilter() {
      this.filterOptions = {};
      this.updateFilters();
    },
  },
  name: "FilterBilletsModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>
