<template>
  <el-dialog
    :title="ModalTitle"
    v-model="shouldShow"
    :loading="isLoadingBillet"
  >
    <el-form :disabled="!isNew">
      <el-row type="flex" justify="start">
        <label class="form-label">Carteira:</label>
      </el-row>
      <el-select
        remote
        @visible-change="fetchAccounts"
        :loading="!accounts"
        loading-text="Carregando carteiras"
        v-model="billet_.firm_bank_account_id"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="account in Accounts"
          :key="account"
          :disabled="
            !account.account_id ||
            !account.agreement_id ||
            !account.can_emit_billets
          "
          :label="account.account_label"
          :value="account.uid"
        ></el-option>
      </el-select>
      <el-row type="flex" justify="start">
        <label class="form-label">Cliente:</label>
      </el-row>
      <el-select
        remote
        filterable
        :filter-method="fetchPartners"
        :loading="!partners"
        loading-text="Carregando clientes"
        v-model="billet_.partner_id"
        size="medium"
        value-key="uid"
      >
        <el-option
          v-for="account in Partners"
          :key="account"
          :label="account.name"
          :value="account.uid"
        ></el-option>
      </el-select>
      <el-row type="flex" justify="start">
        <label class="form-label">Vencimento:</label>
      </el-row>
      <el-date-picker
        v-model="billet_.expires_at"
        type="date"
        size="medium"
        format="DD/MM/YYYY"
      >
      </el-date-picker>

      <el-row :gutter="4" type="flex">
        <el-col :md="12"
          ><base-input
            label="Valor:"
            hint="R$"
            type="money"
            v-model="billet_.amount"
          >
          </base-input
        ></el-col>
        <el-col :md="12"
          ><base-input
            label="Código de integração:"
            hint="Código na TecnoSpeed"
            disabled
            v-model="billet_.billet_id"
          >
          </base-input
        ></el-col>
      </el-row>

      <el-row :gutter="4" type="flex">
        <el-col :md="12"
          ><base-input
            label="Valor Pago:"
            disabled
            type="money"
            v-model="billet_.amount_paid"
          >
          </base-input
        ></el-col>
        <el-col :md="12"
          ><base-input
            label="Data Pagamento:"
            disabled
            v-model="billet_.paid_at"
          >
          </base-input
        ></el-col>
      </el-row>
      <div v-if="!isNew">
        <el-divider></el-divider>
        <el-descriptions
          title="Dados de Integração"
          direction="vertical"
          :column="1"
          size="md"
          border
        >
          <el-descriptions-item label="Situação">{{
            billet_?.billetAPI?.situacao || ""
          }}</el-descriptions-item>
          <el-descriptions-item label="Motivo">{{
            billet_?.billetAPI?.motivo || ""
          }}</el-descriptions-item>
          <el-descriptions-item label="Linha Digitavel" :span="2">{{
            billet_?.billetAPI?.TituloLinhaDigitavel
          }}</el-descriptions-item>
          <el-descriptions-item label="Codigo de Barras">
            {{ billet_?.billetAPI?.TituloCodigoBarras }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          :disabled="!isNew"
          @click="createBillet"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import AccountService from "../../services/accounts";
import PartnerService from "../../services/partners";
import BilletService from "../../services/billets";
import { notifyError, notifySuccess } from "../../utils/notifiers";

export default {
  props: ["billet", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      billet_: {},
      billetAPI: {},
      isLoadingSave: false,
      partners: null,
      accounts: null,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    Partners() {
      return this.partners?.data || [];
    },
    isNew() {
      return !this.billet;
    },
    Accounts() {
      return this.accounts || [];
    },
    ModalTitle() {
      return this.isNew ? "Cadastrar boleto" : "Visualizar boleto";
    },
  },
  watch: {
    showModal(v) {
      if (v) {
        this.findBillet();
        if (!this.billet) this.billet_ = {};
      }
    },
    billet(v) {
      if (v) this.billet_ = v;

      if (v?.partner) {
        this.partners = { data: [v.partner] };
        this.billet_.partner_id = v.partner.uid;
      }

      if (v?.account) {
        this.accounts = [v.account];
        this.billet_.firm_bank_account_id = v.account.uid;
      }
    },
  },
  methods: {
    async findBillet() {
      if (!this.isNew && this.billet?.billet_id) {
        this.isLoadingBillet = true;

        const { billet } = await BilletService(this.billet.uid).get({
          billetIntegration: this.billet?.billet_id,
          firmCNPJ: this.billet?.account?.firm?.cnpj,
        });

        this.isLoadingBillet = false;

        if (billet) {
          this.billet_ = {
            ...billet,
            partner_id: billet.partner.uid,
            firm_account_id: billet.account.uid,
          };
        }
      }
    },
    async createBillet() {
      const { billet, error } = await BilletService().create(this.billet_);

      if (billet) {
        this.$emit("should-update");
        this.$emit("close-modal");
        this.billet_ = {};
        notifySuccess("Boleto cadastrado com sucesso");
      } else notifyError("Ocorreu um erro", error.message);
    },
    async fetchPartners(searchName) {
      const { partners } = await PartnerService().index({
        searchName,
        pagination: 1,
      });

      if (partners) this.partners = partners;
    },
    async fetchAccounts(shouldFetch) {
      if (shouldFetch) {
        const { accounts } = await AccountService().index();

        if (accounts) this.accounts = accounts;
      }
    },
  },
  name: "BilletModal",
};
</script>
<style scoped>
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}

.form-label {
  margin-top: 8px;
  font-weight: 600;
}
</style>