import { ElNotification, ElMessageBox } from "element-plus";
export const notifySuccess = (
  title = "A operação foi realizada com sucesso",
  message = ""
) => {
  ElNotification.success({ title: title, message: message });
};
export const notifyError = (
  title = "Não foi possível realizar essa operação",
  message = "Verifique se você tem permissão ou se o servidor está funcional"
) => {
  ElNotification.error({ title, message });
};
export const messageOnCenter = (
  title = "Temos um problema",
  message = "Existe um problema e não podemos avançar com sua requisição",
  type = "info"
) => {
  ElMessageBox({ title, message, type });
};
export const confirmOnCenter = ElMessageBox.confirm;
export const promptOnCenter = ElMessageBox.prompt;
//export { notifySuccess, notifyError, messageOnCenter, confirmOnCenter };
